// siteNav
let navBtn = document.querySelector('.menu_button-inner'),
    navBtnLine = document.querySelectorAll('.menu_button-line'),
    navBtnBody = document.querySelector('.menu_button-body'),
    navBtnContent = document.querySelector('.menu_button-content'),
    mainNav = document.querySelector('.menu_nav-wrapper');

function onOpenNavMainClick() {
    navBtnLine.forEach((item) => {
        item.classList.toggle('menu_button-line-active')
    });
    navBtnBody.classList.toggle('menu_button-body-active');
    navBtnContent.classList.toggle('menu_button-content-active');
    mainNav.classList.toggle('menu_nav-wrapper-active');
}

navBtn.addEventListener('click', onOpenNavMainClick);

// index_link
let indexLink = document.querySelector('.next_page-link'),
    nextPageEl = document.querySelector('#section-one');

function onToNextPageClick(e) {
    e.preventDefault();
    nextPageEl.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
    });
}

if (indexLink) {
    indexLink.addEventListener('click', onToNextPageClick);
}


let categoryLink = document.querySelectorAll('.filter_nav-link');

function onActiveCategoryLink(e) {
    e.preventDefault();
    let currentLink = e.currentTarget;
    categoryLink.forEach(item => {
        if (currentLink === item) {
            item.classList.add('active');
        } else {
            item.classList.remove('active');
        }
    });
}

categoryLink.forEach(item => {
    item.addEventListener('click', onActiveCategoryLink);
});


// selection_tabs
(function () {
    let tabNav = document.querySelectorAll('.tab-link'),
        tabContent = document.querySelectorAll('.tab-js'),
        tabName;

    tabNav.forEach(item => {
        item.addEventListener('click', selectTabNav)
    });

    function selectTabNav(e) {
        e.preventDefault()
        tabNav.forEach(item => {
            item.classList.remove('active');
        });
        this.classList.add('active');
        tabName = this.getAttribute('data-tab-name');
        selectTabContent(tabName);
    }

    function selectTabContent(tabName) {
        tabContent.forEach(item => {
            item.classList.contains(tabName) ? item.classList.add('show') : item.classList.remove('show');
        })
    }
})();

// rating_star
(function () {
    let ratingLink = document.querySelectorAll('.rating_star-link');

    function onRatingRankClick(e) {
        e.preventDefault();
        ratingLink.forEach(item => {
            item.classList.remove('rating_star-link-active');
        });
        let currentStar = e.currentTarget;
        currentStar.classList.add('rating_star-link-active');
        while ((currentStar = currentStar.previousElementSibling)) {
            currentStar.classList.add('rating_star-link-active')
        }
    }

    ratingLink.forEach(item => {
        item.addEventListener('click', onRatingRankClick);
    });
})();

// search-wrapper
(function () {
    let searchEl = document.querySelector('.search-wrapper');
    let searchSubmitBtn = document.querySelector('.search_submit-button');

    function onOpenSearchFormClick(e) {
        e.stopPropagation();
        this.classList.add('search_wrapper-active');
        setTimeout(disabledSearchBtn, 300);
    }

    function disabledSearchBtn() {
        searchSubmitBtn.disabled = false;
    }

    searchEl.addEventListener('click', onOpenSearchFormClick);

    document.addEventListener('click', function () {
        searchEl.classList.remove('search_wrapper-active');
        searchSubmitBtn.disabled = true;
    }, false);
})();

let screenMedium = window.matchMedia("(max-width:768px)");
if (screenMedium.matches) {
    let accountBar = document.querySelector('.account-bar');
    let accountNav = document.querySelector('.account-nav');
    let accountList = document.querySelector('.account-list');

    function onOpenAccountNavClick() {
        accountNav.classList.toggle('account-nav-active');
        accountList.classList.toggle('account-list-active');
    }

    accountBar.addEventListener('click', onOpenAccountNavClick, false);
}

